import React from "react";

const TypingIntro: React.FC = () => {
  return (
    <>
      <div className="typewriter">
        <h1>Hey there, my name is</h1>
      </div>
    </>
  );
};

export default TypingIntro;
