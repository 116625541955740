import React from "react";

const NameIntro: React.FC = () => {
  return (
    <>
      <div className="name_intro">
        <h1>Muhammad Umer Fiaz!</h1>
      </div>
    </>
  );
};

export default NameIntro;
